import { httpClient } from './httpClient.js';

const
    LISTPAYMENTLINKS = 'dashboard/orders',
    GETPAYMENTLINKDETAILS = 'dashboard/orders/',
    CREATEPAYMENTLINK = 'dashboard/orders';

const listPaymentLinks = (listParams) => httpClient.dashboardService.get(LISTPAYMENTLINKS, { "params": listParams });
const getPaymentLink = (id, expandParams) => httpClient.dashboardService.get(GETPAYMENTLINKDETAILS + id, { "params": expandParams });
const createPaymentLink = async (orderParams) => { return await httpClient.dashboardService.post(CREATEPAYMENTLINK, orderParams) };
export {
    listPaymentLinks,
    getPaymentLink,
    createPaymentLink
};