<template>
  <div>
    <CRow>
      <CCol col="12" xl="12">
        <CCard>
          <CCardBody>
            <CRow>
              <CCol class="pills-wrapper">
                <b-nav pills>
                  <b-nav-item
                    @click="selectStatus('All')"
                    :active="active === 'All'"
                    >All</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('started')"
                    :active="active === 'started'"
                    >Unpaid</b-nav-item
                  >
                  <b-nav-item
                    @click="selectStatus('completed')"
                    :active="active === 'completed'"
                    >Paid</b-nav-item
                  >
                  <!--adding processing in the expired for the time being till the api send correct response-->
                  <b-nav-item
                    @click="selectStatus('processing')"
                    :active="active === 'processing'"
                    >Expired</b-nav-item
                  >
                  <!--settled not defined from the api yet, todo-->
                  <!--<b-nav-item
                    @click="selectStatus('settled')"
                    :active="active === 'settled'"
                    >Settled</b-nav-item
                  >-->
                </b-nav>
              </CCol>
              <CCol>
                <CButton
                  class="px-2 float-right"
                  @click="goTo('payment_links/create')"
                  color="durianprimary"
                >
                  <CIcon name="cil-library-add" />&nbsp;{{
                    $t("PAYMENT_LINKS.CREATE")
                  }}
                </CButton>
                <CInput
                  :placeholder="$t('PAYMENTS.SEARCH')"
                  v-model="orderFilters.search"
                  @input="selectStatus(active)"
                  class="px-2 float-right col-sm-4"
                />

                <CButton
                  class="px-2 float-right"
                  size="sm"
                  color="lightborder"
                  @click="filterModal = true"
                >
                  <CIcon name="cil-filter" />&nbsp;{{ $t("COMMON.FILTERS") }}
                </CButton>
                <vue-json-to-csv
                  v-if="CSVData !== null"
                  :json-data="CSVData"
                  :csv-title="getCSVName('payments_link')"
                  class="px-2 float-right"
                >
                  <CButton
                    size="sm"
                    class="px-2 float-right"
                    color="lightborder"
                    @click="exportCSV"
                  >
                    <CIcon name="cil-cloud-download" />&nbsp;{{
                      $t("COMMON.EXPORT")
                    }}
                  </CButton>
                </vue-json-to-csv>
              </CCol>
            </CRow>
            <CDataTable
              hover
              :items="filtered_payment_links"
              :fields="fields"
              @row-clicked="
                (item, index) => goTo('payment_links/detail/', item['id'])
              "
            >
              <template #status="data">
                <td>
                  <CBadge
                    :color="
                      getPaymentLinkBadge(getPaymentLinkStatus(data.item))
                    "
                  >
                    {{ getPaymentLinkStatus(data.item) }}
                  </CBadge>
                </td>
              </template>
              <template #amount="data">
                <td>
                  <div>{{ formatAmount(data.item.amount) }}</div>
                </td>
              </template>
              <template #reference="data">
                <td>
                  <div>{{ data.item.order_ref_id }}</div>
                </td>
              </template>
              <template #date="data">
                <td>
                  <div>
                    {{ formatDate(data.item.created_at) }}
                  </div>
                </td>
              </template>
            </CDataTable>
            <div class="col-md-11">
              <b-pagination
                size="md"
                align="center"
                :total-rows="totalRows"
                v-model="pageNumber"
                :per-page="orderFilters.limit"
                @change="pageChange"
              ></b-pagination>
            </div>
          </CCardBody>
        </CCard>

        <CModal
          :title="$t('COMMON.FILTERS_HEADER')"
          color="light"
          :no-close-on-backdrop="false"
          :centered="true"
          :show.sync="filterModal"
        >
          <div class="row">
            <label class="col-12 col-xl-12">{{
              $t("PAYMENT_LINKS.LABELS.FILTER_CREATED_DATE")
            }}</label>
            <date-picker
              class="primary col-12 col-xl-12 mb-3"
              v-model="createdDates"
              type="date"
              range
              placeholder="Select date range"
              @change="selectRangeCreatedDate"
            ></date-picker>
          </div>
          <div class="row">
            <label class="col-12 col-xl-12">{{
              $t("PAYMENT_LINKS.LABELS.FILTER_PAID_DATE")
            }}</label>
            <date-picker
              class="primary col-12 col-xl-12 mb-3"
              v-model="paidDates"
              type="date"
              range
              placeholder="Select date range"
              @change="selectRangePaidDate"
            ></date-picker>
          </div>
          <template #footer>
            <CButton @click="resetFilter">{{
              $t("COMMON.RESET")
            }}</CButton>
            <CButton @click="filterModal = false" color="durianprimary">{{
              $t("COMMON.DONE")
            }}</CButton>
          </template>
        </CModal>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import { listPaymentLinks } from "@/api/payment_links.api";
import VueJsonToCsv from "vue-json-to-csv";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import { paymentLink, sandboxEventEmitter } from "@/../config.js";
const PAYMENT_LINK_PREFIX = paymentLink.url.toString();
const COMPONENT_NAME = "PaymentLinks";

export default {
  name: COMPONENT_NAME,
  components: { VueJsonToCsv, DatePicker },
  data() {
    return {
      active: "All",
      totalRows: 0,
      filterModal: false,
      paidDateTo: "",
      paidDateFrom: "",
      createdDateTo: "",
      createdDateFrom: "",
      fields: [
        {
          key: "id",
          label: this.$t("PAYMENT_LINKS.TABLE.ID"),
          _classes: "font-weight-bold",
        },
        { key: "date", label: this.$t("PAYMENT_LINKS.TABLE.DATE") },
        { key: "reference", label: this.$t("PAYMENT_LINKS.TABLE.REFERENCE") },
        { key: "email", label: this.$t("PAYMENT_LINKS.TABLE.CUSTOMER") },
        { key: "amount", label: this.$t("PAYMENT_LINKS.TABLE.AMOUNT") },
        { key: "status", label: this.$t("PAYMENT_LINKS.TABLE.STATUS") },
      ],
      orderFilters: {
        skip: 0,
        limit: 50,
        filters: [],
        from: "",
        to: "",
        search: "",
        payment_links: true,
      },
      pageNumber: 1,
      limit: 50,
      payment_links: [],
      filtered_payment_links: [],
      CSVData: [],
      rangeCreatedDate: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      rangePaidDate: {
        start: new Date(2020, 0, 1),
        end: new Date(),
      },
      createdDates: [],
      paidDates: [],
    };
  },
  methods: {
    pageChange(val) {
      this.orderFilters.skip = (val - 1) * this.orderFilters.limit;
      this.getPaymentLinks();
    },
    selectRangeCreatedDate() {
      if (
        this.ifValid(this.createdDates[0]) &&
        this.ifValid(this.createdDates[1])
      ) {
        this.orderFilters.from = this.moment(this.createdDates[0])
          .format("YYYY-MM-DD")
          .toString();
        this.orderFilters.to = this.moment(this.createdDates[1])
          .format("YYYY-MM-DD")
          .toString();
      } else {
        this.orderFilters.from = "";
        this.orderFilters.to = "";
      }
      this.getPaymentsByDate();
    },
    selectRangePaidDate() {
      if (
        this.ifValid(this.paidDates[0]) &&
        this.ifValid(this.paidDates[1])
      ) {
        const paidDateFrom = this.moment(this.paidDates[0]).format("YYYY-MM-DD").toString();
        const paidDateTo = this.moment(this.paidDates[1]).format("YYYY-MM-DD").toString();
        this.orderFilters.filters.push({ value: `${paidDateFrom} ${paidDateTo}`, key: "paid_date" });
      }
      this.getPaymentLinks();
    },
    resetFilter(){
      this.createdDates = [];
      this.paidDates = [];
      this.orderFilters.from = "";
      this.orderFilters.to = "";
      this.orderFilters.filters.paidDateFrom = "";
      this.orderFilters.filters.paidDateTo = "";
      this.getPaymentLinks();
    },
    onContextFrom(context) {
      if (context.selectedDate != null) {
        let date = this.moment(context.selectedDate).format("MM-DD-YYYY");
        this.orderFilters.from = date.toString();
      } else {
        this.orderFilters.from = "";
      }
      this.getPaymentsByDate();
    },
    onContextTo(context) {
      if (context.selectedDate != null) {
        let date = this.moment(context.selectedDate).format("MM-DD-YYYY");
        this.orderFilters.to = date.toString();
      } else {
        this.orderFilters.to = "";
      }
      this.getPaymentsByDate();
    },
    getPaymentsByDate() {
      if (
        (this.orderFilters.from && this.orderFilters.to) ||
        (this.orderFilters.from === "" && this.orderFilters.to === "")
      ) {
        this.getPaymentLinks();
      }
    },
    initialiseExportData() {
      let paramsMap = new Map();
      paramsMap.skip = 0;
      paramsMap.limit = this.totalRows;
      paramsMap.from = this.orderFilters.from;
      paramsMap.to = this.orderFilters.to;
      paramsMap.search = "";
      paramsMap.payment_links = this.orderFilters.payment_links;
      paramsMap.filters = JSON.stringify(this.orderFilters.filters);
      listPaymentLinks(this.generatePostParams(paramsMap))
        .then((response) => {
          this.CSVData = response.data.data.orders;
        })
        .catch((e) => {
          if (e) this.showToaster(e);
        });
    },
    exportCSV() {
      this.cleanCSVData();
    },
    getPaymentLinks() {
      const {
        skip,
        limit,
        from,
        to,
        search,
        payment_links,
        filters,
      } = this.orderFilters;
      let paramsMap = new Map();
      paramsMap.skip = skip;
      paramsMap.limit = limit;
      paramsMap.from = from;
      paramsMap.to = to;
      paramsMap.search = search;
      paramsMap.payment_links = payment_links;
      paramsMap.filters = JSON.stringify(filters);
      listPaymentLinks(this.generatePostParams(paramsMap))
        .then((response) => {
          this.payment_links = response.data.data.orders;
          this.filtered_payment_links = this.payment_links;
          this.totalRows = response.data.data.count;
          this.applyFilters();
        })
        .catch((e) => {
          if (e) this.showToaster(e);
        });
    },
    removeFromFilter(value) {
      let removeIndex = -1;
      this.orderFilters.filters.find((v, i) => {
        if (v.key === value) {
          removeIndex = i;
        }
      });
      if (removeIndex != -1) {
        this.orderFilters.filters.splice(removeIndex, 1);
      }
    },
    cleanCSVData() {
      this.CSVData = this.filtered_payment_links;
      if (this.CSVData) {
        let csvDataLength = this.CSVData.length;
        if (csvDataLength > 0) {
          for (let i = 0; i < csvDataLength; i++) {
            this.CSVData[i].created_at = this.formatDate(
              this.CSVData[i].created_at
            );
            this.CSVData[i].updated_at = this.formatDate(
              this.CSVData[i].updated_at
            );
            this.CSVData[i].payment_details_type = this.getPayType(
              this.CSVData[i].payment_details_type
            );
            this.CSVData[i].expiry_date = this.formatDate(
              this.CSVData[i].expiry_date
            );
            this.CSVData[i].payment_link_url =
              PAYMENT_LINK_PREFIX + this.CSVData[i].payment_link_url;
          }
        }
      }
    },
    applyFilters() {
      this.filterByPaidDate();
    },
    filterPaidDateMethod(paidDate) {
      const rangedPaidDateFrom =
        this.paidDateFrom === ""
          ? this.moment(this.minDate()).format("MM-DD-YYYY")
          : this.paidDateFrom;
      const rangedPaidDateTo =
        this.paidDateTo === ""
          ? this.moment(this.maxDate()).format("MM-DD-YYYY")
          : this.paidDateTo;
      return (
        new Date(paidDate) <= new Date(rangedPaidDateTo) &&
        new Date(paidDate) >= new Date(rangedPaidDateFrom)
      );
    },
    filterByPaidDate() {
      if (this.paidDateTo !== "" && this.paidDateFrom !== "") {
        this.filtered_payment_links = this.payment_links.filter(
          (paymentLink) =>
            paymentLink.status === "completed" &&
            this.filterPaidDateMethod(paymentLink.updated_at)
        );
      } else if (this.paidDateTo === "" && this.paidDateFrom === "") {
        this.filtered_payment_links = this.payment_links;
      }
    },
    getPaymentLinkStatus(item) {
      let expiry = new Date(item.expiry_date);
      let currentDate = new Date();
      let dateDiff = currentDate - expiry;

      if (item.status === "processing" && dateDiff > 0) {
        return "expired";
      }
      if (item.status === "completed") {
        return "paid";
      }
      return "unpaid";
    },
    selectStatus(filterBy) {
      this.active = filterBy;
      this.removeFromFilter("status");
      if (filterBy !== "All") {
        this.orderFilters.filters.push({ value: filterBy, key: "status" });
      }
      this.getPaymentLinks();
    },
    initialiseData() {
      this.getPaymentLinks();
      this.initialiseExportData();
    },
  },
  mounted() {
    this.initialiseData();
  },
  created() {
    this.$eventHub.$on(sandboxEventEmitter.prefix + COMPONENT_NAME, this.initialiseData);
  },
  beforeDestroy(){
    this.$eventHub.$off(sandboxEventEmitter.prefix + COMPONENT_NAME);
  }
};
</script>
<style>
.header-button {
  margin-left: 1rem;
}
</style>
